import React from "react";

const VideoItem = ({ video }) => {
  return (
    <div
      className={video.isRight ? "video-item video-item-right" : "video-item"}
    >
      <video
        src={`./img/video/${video.video}.mp4`}
        poster="./img/video/poster.png"
        controls
      ></video>
      <div className="video-info">
        <h4>Відео відгук від {video.from}</h4>
        <p>“{video.text}”</p>
        <h3>{video.author}</h3>
        <span>{video.about}</span>
      </div>
    </div>
  );
};

export default VideoItem;
