import React from "react";

const OfferItem = ({ offer }) => {
  return (
    <div className="offer">
      <img src={`./img/offers/offer${offer.id}.svg`} alt="Offer" />
      <span>{offer.title}</span>
      <p>{offer.text}</p>
    </div>
  );
};

export default OfferItem;
