import "./Form.css";

const Form = () => {
  return (
    <div className="form-block">
      <div className="wrapper form-wrapper">
        <div className="form" id="form">
          <form action="./telegram.php" method="POST" className="form-main">
            <h2>Готові зробити перший крок до успіху?</h2>
            <p>
              Заповніть форму і ми прорахуємо вартість цільового звернення у
              Вашому бізнесі.
            </p>
            <div className="form-inputs">
              <div className="form-input">
                <label htmlFor="name">Ваше імʼя</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Іван"
                  required
                />
              </div>
              <div className="form-input">
                <label htmlFor="name">Номер телефону</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder={"+380(00)0000000"}
                  required
                />
              </div>
            </div>
            <button className="btn-primary">Відправити</button>
          </form>
          <div className="form-img"></div>
        </div>
      </div>
    </div>
  );
};

export default Form;
