import React from "react";

const MenuItem = ({ menu }) => {
  return (
    <div className="menu-item">
      <img src={`./img/menu/${menu.img}.svg`} alt="target" />
      <div className="item-text">
        <h3>{menu.title}</h3>
        <div className="item-text-area">{menu.text}</div>
      </div>
      <button className="btn-primary item-text-btn">
        <a href="#form">Замовити</a>
      </button>
    </div>
  );
};

export default MenuItem;
