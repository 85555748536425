import React from "react";
import "./Cases.css";
import CaseItem from "./CaseItem";

const caseBase = [
  {
    title: "1+1 MEDIA SCHOOL",
    span: "Освітній проєкт 1+1 Мedia",
    results: ["- 500 лідів", "- $2 ціна ліда", "- $1000 рекламний бюджет"],
    isLeft: false,
    img: "oneplusone",
  },
  {
    title: "Івент-агенство",
    span: "Найбільше агенство в сфері романтичних послуг в Україні",
    results: [
      "- 10 035 лідів",
      "- $1,3 ціна ліда",
      "- $13 045 рекламний бюджет",
    ],
    isLeft: true,
    img: "dating",
  },
  {
    title: "Loza_center_odessa",
    span: "Центр лазерної подології та дерматології",
    results: ["- 700 лідів", "- $1.4 ціна ліда", "- $980 рекламний бюджет"],
    isLeft: false,
    img: "beauty",
  },
  {
    title: "Fujitsu",
    span: "Обладнення для енергонезалежності",
    results: ["- 5500 лідів ", "- $1 ціна ліда", "- $5500 рекламний бюджет"],
    isLeft: true,
    img: "fujitsu",
  },
  {
    title: "Two_hands_massage_vn",
    span: "Студія масажу та навчання в цій сфері",
    results: ["- 2500 лідів", "- $1.5 ціна ліда", "- $3750 рекламний бюджет"],
    isLeft: false,
    img: "massage",
  },
];

const Cases = () => {
  return (
    <div className="cases-block" id="cases">
      <div className="wrapper cases-wrapper">
        <span className="block-span">Наші кейси</span>
        <h2 className="block-title">
          Від приголомшливих дизайнів до успішних кампаній
        </h2>
        <div className="cases">
          {caseBase.map((el) => {
            return <CaseItem caseInfo={el} key={el.title} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Cases;
