import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="wrapper2 footer-wrapper">
        <div className="footer-left">
          <img
            src="./img/header/logo.png"
            alt="Logotype"
            className="footer-logo"
          />
          <p>
            Згенеруємо перші заявки за 3 дні або повернемо усі витрати на
            рекламу
          </p>
          <div className="footer-social">
            <a href="">
              <img src="./img/footer/fb.svg" alt="social" />
            </a>
            <a href="">
              <img src="./img/footer/li.svg" alt="social" />
            </a>
            <a href="">
              <img src="./img/footer/yt.svg" alt="social" />
            </a>
          </div>
        </div>
        <div className="footer-center">
          <h5>Навігація</h5>
          <ul>
            <li>
              <a href="#about">Про нас</a>
            </li>
            <li>
              <a href="#cases">Кейси</a>
            </li>
            <li>
              <a href="#menu">Послуги</a>
            </li>
            <li>
              <a href="#video">Відгуки</a>
            </li>
          </ul>
        </div>
        <div className="footer-right">
          <h5>Контакти</h5>
          <ul>
            <li>
              <img src="./img/footer/user.svg" alt="contacts" /> lemotag.agency
            </li>
            <li>
              <a href="tel:098-866-65-12">
                <img src="./img/footer/phone.svg" alt="contacts" /> (098)
                866-65-12
              </a>
            </li>
            <li>
              <a href="mailto:lemotag.agency@gmail.com">
                <img src="./img/footer/mail.svg" alt="contacts" />{" "}
                lemotag.agency@gmail.com
              </a>
            </li>

            <li>
              <img src="./img/footer/share.svg" alt="contacts" />{" "}
              https://lemotag.agency/
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
