import React from "react";
import "./Partners.css";

const Partners = () => {
  return (
    <div className="wrapper partners-wrapper">
      <h3>Наші партнери</h3>
      <div className="img-rows">
        <div className="img-row">
          <img src="./img/partners/logo2.png" alt="Partner" />
          <img src="./img/partners/logo1.png" alt="Partner" />
          <img src="./img/partners/logo4.png" alt="Partner" />
          <img src="./img/partners/logo7.png" alt="Partner" />
          <img src="./img/partners/logo5.png" alt="Partner" />
        </div>
        <div className="img-row img-row-tighter">
          <img src="./img/partners/logo6.png" alt="Partner" />
          <img src="./img/partners/logo10.png" alt="Partner" />
          <img src="./img/partners/logo9.png" alt="Partner" />
          <img src="./img/partners/logo3.png" alt="Partner" />
          <img src="./img/partners/logo8.png" alt="Partner" />
        </div>
      </div>
    </div>
  );
};

export default Partners;
