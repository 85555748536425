import VideoItem from "./VideoItem";
import "./Videos.css";

const videoBase = [
  {
    video: "3",
    isRight: false,
    from: "Гуменюка Олексія",
    author: "Гуменюк Олексій",
    about: "Представник магазину Karcher",
    text: "Команда підійшла креативно до нашого проєкту, збільшилася впізнаваність магазину та кількість клієнтів, працюємо півроку, рекомендуємо.",
  },
  {
    video: "4",
    isRight: true,
    from: "Костюка Андрія",
    author: "Костюк Андрій",
    about: "Підприємець",
    text: "Дуже задоволений, рекомендую.",
  },
  {
    video: "2",
    isRight: false,
    from: "Звєрова Віктора",
    author: "Звєров Віктор",
    about: "Представник магазину Oleo-Mac",
    text: "Команда підійшла фундаментально до нашого проєкту, були розроблені посадочні сторінки, контент-план, працюємо вже півроку, радимо.",
  },
  // {
  //   video: "5",
  //   isRight: true,
  //   from: "Потьомкіної Марини",
  //   author: "Потьомкіна Марина",
  //   about: "Директор маркетингового агенства XLUX",
  //   text: "Все було зроблено вчасно, отримали велику кількість теплих лідів, та клієнтів, будемо продовжувати працювати.",
  // },
  {
    video: "6",
    isRight: true,
    from: "Пилипенко Євгена",
    author: "Пилипенко Євген",
    about: "Власник масажної студії Two hands",
    text: "Всі процеси робилися швидко, задоволений результатами, віддача була максимальна проєкту, будемо далі працювати.",
  },
  {
    video: "1",
    isRight: false,
    from: "Гаврилюка Романа",
    author: "Гаврилюк Роман",
    about: "Web дизайнер",
    text: "Отримав клієнтів вже в перший день налаштування рекламних компаній.",
  },
];

const Videos = () => {
  return (
    <div className="videos-block" id="video">
      <div className="wrapper videos-wrapper">
        <span className="block-span">Відгуки</span>
        <h2 className="block-title">
          Ми пишаємося своєю репутацією – дізнайтеся чому, у відгуках наших
          клієнтів.
        </h2>
        <div className="videos">
          {videoBase.map((el) => {
            return <VideoItem video={el} key={el.video} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Videos;
