import React from "react";

const CaseItem = ({ caseInfo }) => {
  return (
    <div className={caseInfo.isLeft ? "case case-left" : "case"}>
      <div className="case-info">
        <h3>{caseInfo.title}</h3>
        <span>{caseInfo.span}</span>
        <ul>
          {caseInfo.results.map((el, ind) => {
            return <li key={ind}>{el}</li>;
          })}
        </ul>
      </div>
      <img src={`./img/cases/${caseInfo.img}.png`} alt="Case" />
    </div>
  );
};

export default CaseItem;
