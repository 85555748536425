import "./BurgerMenu.css";

const BurgerMenu = ({ onClick }) => {
  return (
    <div className="burger-menu">
      <img
        src="./img/header/close.svg"
        alt="Close"
        className="close-btn"
        onClick={onClick}
      />
      <ul>
        <li onClick={onClick}>
          <a href="#about">Про нас</a>
        </li>
        <li onClick={onClick}>
          <a href="#cases">Кейси</a>
        </li>
        <li onClick={onClick}>
          <a href="#menu">Послуги</a>
        </li>
        <li onClick={onClick}>
          <a href="#video">Відгуки</a>
        </li>
      </ul>
      <a href="#form" onClick={onClick}>
        <button className="btn-primary">Звʼязатися</button>
      </a>
      <a className="burger-phone" href="tel:098-866-65-12">
        <img src="./img/header/phone.svg" alt="Number" />
        (098) 8666512
      </a>
    </div>
  );
};

export default BurgerMenu;
