import Cases from "./components/Cases/Cases";
import Footer from "./components/Footer/Footer";
import Form from "./components/Form/Form";
import Header from "./components/Header/Header";
import Menu from "./components/Menu/Menu";
import Offers from "./components/Offers/Offers";
import Partners from "./components/Partners/Partners";
import Videos from "./components/Videos/Videos";

function App() {
  return (
    <div className="container">
      <Header />
      <Partners />
      <Offers />
      <Cases />
      <Menu />
      <Videos />
      <Form />
      <Footer />
    </div>
  );
}

export default App;
