import React from "react";
import "./Offers.css";
import OfferItem from "./OfferItem";

const firstRow = [
  {
    id: 1,
    title: "Гарантії",
    text: "Даємо виправдані гарантії і готові підписувати з клієнтом договір NDA",
  },
  {
    id: 2,
    title: "Підтримка 24/7",
    text: "Наша цілодобова підтримка дозволяє вирішувати питання будь-коли",
  },
  {
    id: 3,
    title: "Економічний маркетинг",
    text: "Ставимо особливий акцент на забезпечення окупності рекламного бюджету",
  },
];
const secondRow = [
  {
    id: 4,
    title: "Компетентність",
    text: "В нашій команді працюють досвідчені фахівці, які працюють на найкращий результат",
  },
  {
    id: 5,
    title: "Дослідження",
    text: "Ми вивчаємо бізнес кожного клієнта і труднощі його просування",
  },
  {
    id: 6,
    title: "Інноваційний маркетинг",
    text: "Ми постійно стежимо за трендами рекламного ринку та впроваджуємо інноваційні методи просування",
  },
];

const Offers = () => {
  return (
    <div className="wrapper offers-wrapper" id="about">
      <span className="block-span">Про нас</span>
      <h2 className="block-title">Чому обирають саме нас?</h2>
      <div className="offers">
        <div className="offers-row">
          {firstRow.map((el) => {
            return <OfferItem offer={el} key={el.id} />;
          })}
        </div>
        <div className="offers-row">
          {secondRow.map((el) => {
            return <OfferItem offer={el} key={el.id} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Offers;
