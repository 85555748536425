import "./Header.css";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { useState } from "react";

const Header = () => {
  const [menu, setMenu] = useState(false);
  const openMenu = () => {
    setMenu(true);
  };
  const closeMenu = () => {
    setMenu(false);
  };
  return (
    <>
      {menu && <BurgerMenu onClick={closeMenu} />}
      <header>
        <div className="wrapper2 header-wrapper">
          <img
            src="./img/header/logo.png"
            alt="Logotype"
            className="header-logo"
          />
          <ul>
            <li>
              <a href="#about">Про нас</a>
            </li>
            <li>
              <a href="#cases">Кейси</a>
            </li>
            <li>
              <a href="#menu">Послуги</a>
            </li>
            <li>
              <a href="#video">Відгуки</a>
            </li>
          </ul>
          <a href="#form">
            <button className="btn-primary">Зв'язатися</button>
          </a>
          <img
            src="./img/header/burger.svg"
            alt="Burger"
            className="header-burger"
            onClick={openMenu}
          />
        </div>
      </header>
      <main>
        <div className="wrapper2 header-block-wrapper">
          <div className="header-block-text">
            <h1>
              Згенеруємо перші заявки за 3 дні <br /> або повернемо усі витрати
              на <br />
              рекламу.
            </h1>
            <p>Хочете дізнатися вартість заявки у ваш бізнес?</p>
            <a href="#form">
              <button className="btn-primary">Дізнатися вартість</button>
            </a>
          </div>
          <img src="./img/header/misha.png" alt="Misha" />
        </div>
      </main>
    </>
  );
};

export default Header;
